import React, { useReducer } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
// @ts-ignore - TODO: add types
import { Tag } from '@dt/material-components';
// @ts-ignore
import { palette } from '@dt/theme';
var useStyles = makeStyles({
    link: { fontSize: 13, color: palette.blue20, textDecoration: 'underline', marginTop: '3px' },
    button: {
        all: 'unset',
        ontSize: 13,
        color: palette.blue20,
        textDecoration: 'underline',
        marginTop: '3px',
        cursor: 'pointer',
    },
    tags: {
        display: 'grid',
        gap: 8,
        gridTemplateColumns: '1fr 1fr',
        '&>span': {
            justifyContent: 'flex-start',
            '&>span': {
                maxWidth: function (props) { return (props.largeTags ? 150 : 65); },
            },
        },
    },
    tagsNotExpandable: {
        display: 'grid',
        gap: 8,
        gridTemplateColumns: '1fr 1fr 1fr',
        '&>span': {
            justifyContent: 'flex-start',
            '&>span': {
                maxWidth: function (props) { return (props.largeTags ? 150 : 65); },
            },
        },
    },
});
var InventoryLayoutApiAssetsTabTableRowTags = function (_a) {
    var _b, _c, _d, _e;
    var tags = _a.tags, _f = _a.largeTags, largeTags = _f === void 0 ? false : _f;
    var _g = useReducer(function (state) { return !state; }, false), isExpanded = _g[0], toggleIsExpanded = _g[1];
    var isExpandable = tags.length > 2;
    var styles = useStyles({ largeTags: largeTags });
    if (!isExpandable) {
        return (React.createElement("div", { className: styles.tagsNotExpandable }, tags.map(function (tag) { return (React.createElement(Tag, { key: tag === null || tag === void 0 ? void 0 : tag.id, noPadding: true, label: (tag === null || tag === void 0 ? void 0 : tag.tag) || '', width: "100%" })); })));
    }
    return (React.createElement(Box, { style: { display: 'flex', gap: '8px', alignItems: 'flex-start' } },
        React.createElement("div", { className: styles.tags }, isExpanded ? (tags.map(function (tag) { return React.createElement(Tag, { key: tag === null || tag === void 0 ? void 0 : tag.id, noPadding: true, label: (tag === null || tag === void 0 ? void 0 : tag.tag) || '', width: "100%" }); })) : (React.createElement(React.Fragment, null,
            React.createElement(Tag, { key: (_b = tags[0]) === null || _b === void 0 ? void 0 : _b.id, noPadding: true, label: ((_c = tags[0]) === null || _c === void 0 ? void 0 : _c.tag) || '', width: "100%" }),
            React.createElement(Tag, { key: (_d = tags[1]) === null || _d === void 0 ? void 0 : _d.id, noPadding: true, label: ((_e = tags[1]) === null || _e === void 0 ? void 0 : _e.tag) || '', width: "100%" })))),
        React.createElement("button", { className: styles.button, onClick: toggleIsExpanded }, isExpanded ? 'Less -' : "+ ".concat(tags.length - 2, " more"))));
};
export default InventoryLayoutApiAssetsTabTableRowTags;
