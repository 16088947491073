import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
var useStyles = makeStyles({
    mainList: {
        maxHeight: 350,
        overflow: 'scroll',
        overflowX: 'auto',
    },
    list: {
        padding: 0,
        paddingLeft: '16px',
    },
    itemLogo: {
        justifyContent: 'center',
    },
});
var TableFiltersGroupSelect = function (_a) {
    var searchLabel = _a.searchLabel, list = _a.list, selectedList = _a.selectedList, onChange = _a.onChange;
    var classes = useStyles();
    var _b = useState(''), keywordState = _b[0], setKeywordState = _b[1];
    var keywordContains = function (item) {
        return _.includes(item === null || item === void 0 ? void 0 : item.toLowerCase(), keywordState.toLowerCase());
    };
    var assetsWithoutCategory = list === null || list === void 0 ? void 0 : list.filter(function (asset) { return !(asset === null || asset === void 0 ? void 0 : asset.value_category); });
    var assetsWithCategory = _.chain((list || []).filter(function (asset) { return asset === null || asset === void 0 ? void 0 : asset.value_category; }))
        // Group the elements of Array based on `color` property
        .groupBy('value_category')
        // `key` is group's name (color), `value` is the array of objects
        .map(function (value, key) { return ({ value: key, assets: value }); })
        .value();
    var handleToggleItems = function (selectedItems) {
        var _a, _b;
        var _c = selectedItems.selectedCategory, selectedCategory = _c === void 0 ? null : _c, _d = selectedItems.selectedValue, selectedValue = _d === void 0 ? null : _d;
        var updatedCategories = selectedList.categories;
        var updatedValues = selectedList.values;
        if (selectedCategory) {
            if ((_a = selectedList.categories) === null || _a === void 0 ? void 0 : _a.includes(selectedCategory)) {
                updatedCategories = updatedCategories.filter(function (filterCategory) { return filterCategory !== selectedCategory; });
            }
            else {
                updatedCategories.push(selectedCategory);
            }
        }
        if (selectedValue) {
            if ((_b = selectedList.values) === null || _b === void 0 ? void 0 : _b.includes(selectedValue)) {
                updatedValues = updatedValues.filter(function (filterValue) { return filterValue !== selectedValue; });
            }
            else {
                updatedValues.push(selectedValue);
            }
        }
        onChange({ categories: updatedCategories, values: updatedValues });
    };
    return (React.createElement(Grid, { container: true, spacing: 1 },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(TextField, { fullWidth: true, value: keywordState, onChange: function (e) { return setKeywordState(e.target.value); }, id: "hosted-on-keyword", label: searchLabel || 'Search', InputProps: {
                    endAdornment: React.createElement(SearchIcon, null),
                } })),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(List, { className: classes.mainList, component: "nav" },
                assetsWithCategory.map(function (category, key) { return (React.createElement(React.Fragment, { key: key },
                    category.value && (!keywordState || keywordContains(category.value)) && (React.createElement(ListItem, { button: true, dense: true, onClick: function () {
                            return handleToggleItems({
                                selectedCategory: category.value,
                            });
                        } },
                        React.createElement(Checkbox, { checked: selectedList.categories.includes(category.value) }),
                        React.createElement(ListItemText, { primary: category.value }))),
                    React.createElement(List, { className: category.value ? classes.list : undefined }, category.assets.map(function (asset, key) {
                        var _a;
                        return asset.name &&
                            (!keywordState || keywordContains(asset.name)) && (React.createElement(ListItem, { key: key, button: true, dense: true, onClick: function () { return handleToggleItems({ selectedValue: asset === null || asset === void 0 ? void 0 : asset.value }); } },
                            React.createElement(Checkbox, { checked: (_a = selectedList.values) === null || _a === void 0 ? void 0 : _a.includes(asset === null || asset === void 0 ? void 0 : asset.value) }),
                            (asset === null || asset === void 0 ? void 0 : asset.icon_url) ? (React.createElement(ListItemIcon, { className: classes.itemLogo },
                                React.createElement("img", { height: 24, width: 24, src: asset.icon_url, alt: asset.name }))) : null,
                            React.createElement(ListItemText, { primary: asset.name })));
                    })))); }), assetsWithoutCategory === null || assetsWithoutCategory === void 0 ? void 0 :
                assetsWithoutCategory.map(function (asset, key) {
                    var _a;
                    return (!keywordState || keywordContains(asset === null || asset === void 0 ? void 0 : asset.name)) && (React.createElement(ListItem, { key: key, button: true, dense: true, onClick: function () { return handleToggleItems({ selectedValue: asset === null || asset === void 0 ? void 0 : asset.value }); } },
                        React.createElement(Checkbox, { checked: (_a = selectedList.values) === null || _a === void 0 ? void 0 : _a.includes(asset === null || asset === void 0 ? void 0 : asset.value) }),
                        (asset === null || asset === void 0 ? void 0 : asset.icon_url) ? (React.createElement(ListItemIcon, { className: classes.itemLogo },
                            React.createElement("img", { height: 24, width: 24, src: asset === null || asset === void 0 ? void 0 : asset.icon_url, alt: asset === null || asset === void 0 ? void 0 : asset.name }))) : null,
                        React.createElement(ListItemText, { primary: asset === null || asset === void 0 ? void 0 : asset.name })));
                })))));
};
export default TableFiltersGroupSelect;
